













import { defineComponent, PropType } from "@vue/composition-api"
import { ParsingError } from "@/utilities/Parser"

export default defineComponent({
    name: "ImporterParseErrorTable",
    props: {
        value: {
            required: true,
            type: Array as PropType<ParsingError[]>,
        },
    },
    setup() {
        const headers = [
            {
                text: "表",
                value: "sheet",
            },
            {
                text: "列",
                value: "row",
            },
            {
                text: "欄",
                value: "col",
            },
            {
                text: "錯誤",
                value: "err",
            },
        ]
        return {
            headers,
        }
    },
})
